import React, { useContext } from "react"
import { Accordion, Card } from "react-bootstrap"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { Fade } from "react-reveal"
import marked from "marked"

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )

  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Card.Header
      className={isCurrentEventKey ? "accordion-active" : "accordion-collapsed"}
    >
      <button
        type="button"
        className="border-0 bg-transparent w-100"
        onClick={decoratedOnClick}
      >
        <div className="btn-plus-minus" />
        <h5
          className={`m-0 text-left ${
            isCurrentEventKey ? "text-primary-gradient" : ""
          }`}
        >
          {children}
        </h5>
      </button>
    </Card.Header>
  )
}

const FaqAccordion = ({ questions }) => {
  console.log(questions)
  return (
    <Accordion defaultActiveKey="0" className="w-100">
      {questions.map(faq => (
        <Card key={faq._key} className="w-100">
          <ContextAwareToggle eventKey={faq._key} className="bg-transparent">
            {faq.title}
          </ContextAwareToggle>
          <Accordion.Collapse eventKey={faq._key}>
            <Card.Body>
              <Fade bottom>
                {faq.body ? (
                  <div
                    className="test"
                    dangerouslySetInnerHTML={{
                      __html: marked(faq.body),
                    }}
                  />
                ) : (
                  "Not yet added"
                )}
              </Fade>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      ))}
    </Accordion>
  )
}

export default FaqAccordion
