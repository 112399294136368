import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Link } from "gatsby"

import oval1 from "@images/oval.svg"
import oval2 from "@images/oval-2.svg"
import oval3 from "@images/oval-3.svg"

import FaqAccordion from "../components/faqAccordion/faqAccordion"

// import { QuestionsTesting } from "../components/faqQuestions/faq-questions"
import { Fade } from "react-reveal"

// svg decorations
// import "@components/svg-decorations/svg-decorations.scss"
import WaveTop from "@components/svg-decorations/waveTop"
import WaveBottom from "@components/svg-decorations/waveBottom"

// Inline styling

const faqContent = {
  padding: "10rem 0 5rem 0",
  position: "relative",
  overflow: "hidden",
}

const heroHeader = {
  fontSize: 40,
  marginBottom: "2rem",
}

const emailContent = {
  position: "relative",
  zIndex: "1",
  paddingTop: "235px",
  paddingBottom: "200px",
  marginBottom: "-20px",
  marginTop: "50px",
  background: "linear-gradient(135deg, #5526fd 0%, #262a82 100%)",
}

const emailRecipient = {
  color: "#13F5F5",
}

const salesContent = {
  padding: "10rem 0",
}

// end of styling

const Faq = ({ data, location }) => {
  const faqs = data && data.sanityCompanyInfo && data.sanityCompanyInfo.faq
  console.log("faqs", faqs)
  return (
    <>
      <Layout location={location}>
        <SEO
          title="LeadRoll FAQs"
          description="Wondering if LinkedIn Lead Generation will work in your niche? Check out our robust FAQ section at LeadRoll to see if scaling B2B lead gen on LinkedIn is right for you."
        />
        <div className="innerpages-hero">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="hero-text-wrapper text-center">
                  <h2 style={heroHeader}>Leadroll FAQ</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faq-content" style={faqContent}>
          <img className="oval-svg" src={oval1} alt="Oval svg" />
          <img className="oval-2" src={oval2} alt="Oval svg" />
          <img className="oval-3" src={oval3} alt="Oval svg" />
          <div className="container">
            <div className="row">
              <div className="col-10 m-auto">
                <div className="mb-5">
                  <FaqAccordion questions={faqs} />
                </div>
                <div className="faq-ac"></div>
              </div>
            </div>
          </div>
        </div>
        <div style={emailContent}>
          <WaveTop />
          <WaveBottom />
          <div className="container text-center">
            <Fade>
              <>
                <h2 className="text-white display-4 pb-3">
                  Still have questions?
                </h2>
              </>
            </Fade>
            <div className="row justify-content-center">
              <div className="col-md-8">
                <p className="lead text-white">
                  Send us an email us at{" "}
                  <a
                    style={emailRecipient}
                    href="mailto:support@leadroll.co?subject=Question%20Regarding%20LeadRoll.co"
                  >
                    Support@leadroll.co
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="blob-bg"></div>
        </div>
        <div style={salesContent}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="mb-5 text-center">
                  <h2 className="text-primary-gradient display-4 pb-3">
                    Fractional Sales Outreach
                  </h2>
                  <p className="lead pb-5">
                    Lead Responses Guaranteed. Hundreds of Hours Saved.
                  </p>
                </div>

                <div className="row justify-content-center">
                  <div className="col-md-6 col-lg-5 mb-4">
                    <Fade className="h-100">
                      <div className="features-content-wrapper h-100">
                        <div className="bg-dark text-center p-3">
                          <h3 className="mb-0 text-white">Pro</h3>
                        </div>
                        <div className="py-3 px-5">
                          <ul className="list-unstyled features-list pb-3">
                            <li className="feature-item">
                              50-70 Prospects Per Day [1500+/m]
                            </li>
                            <li className="feature-item">
                              Lead list built and approved
                            </li>
                            <li className="feature-item">
                              Outreach campaigns written and approved
                            </li>
                            <li className="feature-item">
                              Social outbound prospecting & follow-up
                            </li>
                            <li className="feature-item">
                              Email outbound prospecting & follow-up
                            </li>
                            <li className="feature-item">
                              Validated business emails
                            </li>
                            <li className="feature-item">
                              Enterprise grade VPN login protection
                            </li>
                            <li className="feature-item">
                              Split testing copy monthly
                            </li>
                            <li className="feature-item">
                              Unlimited messaging update requests
                            </li>
                            <li className="feature-item">
                              Monthly video reporting
                            </li>
                            <li className="feature-item">
                              Dmark, Dkim, SPF record verification & installment
                            </li>
                            <li className="feature-item">
                              Spam monitoring/fixing
                            </li>
                            <li className="feature-item">
                              Full domain prep and warmup
                            </li>
                            <li className="feature-item">
                              Enrich & track entire prospect list
                            </li>
                            <li className="feature-item">
                              Social Profile Optimization Guide
                            </li>
                            <li className="feature-item">
                              Gather current and past company urls
                            </li>
                            <li className="feature-item">
                              A.I. lead response qualification
                            </li>
                            <li className="feature-item">
                              Logging email responses
                            </li>
                            <li className="feature-item">
                              Advanced metrics dashboard
                            </li>
                            <li className="feature-item">
                              Current client exclusions
                            </li>
                            <li className="feature-item placeholder text-muted">
                              -
                            </li>
                            <li className="feature-item placeholder text-muted">
                              -
                            </li>
                            <li className="feature-item placeholder text-muted">
                              -
                            </li>
                            <li className="feature-item placeholder text-muted">
                              -
                            </li>
                            <li className="feature-item placeholder text-muted">
                              -
                            </li>
                            <li className="feature-item placeholder text-muted">
                              -
                            </li>
                            <li className="feature-item placeholder text-muted">
                              -
                            </li>
                            <li className="feature-item placeholder text-muted">
                              -
                            </li>
                            <li className="feature-item placeholder text-muted">
                              -
                            </li>
                            <li className="feature-item placeholder text-muted">
                              -
                            </li>
                            <li className="feature-item placeholder text-muted">
                              -
                            </li>
                            <li className="feature-item placeholder text-muted">
                              -
                            </li>
                          </ul>
                        </div>
                        <div className="price-and-cta text-center">
                          <p className="mb-5">
                            Designed for businesses where your average target is
                            worth $10,000+ LTV. LeadRoll consistently produces
                            ridiculous ROI in 6 months, or less.
                            <br />
                            <strong>Lead Response Guaranteed</strong>
                            <br />
                            <strong>
                              <em>Avg 400+ Hours Saved/mo per sales rep</em>
                            </strong>
                          </p>
                          <Link
                            to="/quiz"
                            className="btn btn-primary btn-lg shadow-none"
                          >
                            Get A Price
                          </Link>
                        </div>
                      </div>
                    </Fade>
                  </div>

                  <div className="col-md-6 col-lg-5 mb-4">
                    <Fade delay={500} className="h-100">
                      <div className="features-content-wrapper h-100">
                        <div className="bg-primary-gradient text-white text-center p-3">
                          <h3 className="mb-0">Enterprise</h3>
                        </div>
                        <div className="py-3 px-5">
                          <ul className="list-unstyled features-list pb-3">
                            <li className="feature-item">
                              50-70 Prospects Per Day [1500+/m]
                            </li>
                            <li className="feature-item">
                              Lead list built and approved
                            </li>
                            <li className="feature-item">
                              Outreach campaigns written and approved
                            </li>
                            <li className="feature-item">
                              Social outbound prospecting & follow-up
                            </li>
                            <li className="feature-item">
                              Email outbound prospecting & follow-up
                            </li>
                            <li className="feature-item">
                              Validated business emails
                            </li>
                            <li className="feature-item">
                              Enterprise grade VPN login protection
                            </li>
                            <li className="feature-item">
                              Split testing copy monthly
                            </li>
                            <li className="feature-item">
                              Unlimited messaging update requests
                            </li>
                            <li className="feature-item">
                              Monthly video reporting
                            </li>
                            <li className="feature-item">
                              Dmark, Dkim, SPF record verification & installment
                            </li>
                            <li className="feature-item">
                              Spam monitoring/fixing
                            </li>
                            <li className="feature-item">
                              Full domain prep and warmup
                            </li>
                            <li className="feature-item">
                              Enrich & track entire prospect list
                            </li>
                            <li className="feature-item">
                              Social Profile Optimization Guide
                            </li>
                            <li className="feature-item">
                              Gather current and past company urls
                            </li>
                            <li className="feature-item">
                              A.I. lead response qualification
                            </li>
                            <li className="feature-item">
                              Logging email responses
                            </li>
                            <li className="feature-item">
                              Advanced metrics dashboard
                            </li>
                            <li className="feature-item">
                              Current client exclusions
                            </li>
                            <li className="feature-item text-primary-gradient">
                              Multi-stage Voicemail Drops
                            </li>
                            <li className="feature-item text-primary-gradient">
                              Customized Images in Outbound Campaigns
                            </li>
                            <li className="feature-item text-primary-gradient">
                              Appointment Setting Sales Rep
                            </li>
                            <li className="feature-item text-primary-gradient">
                              Personalized Email First Line
                            </li>
                            <li className="feature-item text-primary-gradient">
                              A.I. Scheduling Assistant
                            </li>
                            <li className="feature-item text-primary-gradient">
                              Direct Dial Numbers
                            </li>
                            <li className="feature-item text-primary-gradient">
                              Technology Based Lead Searches
                            </li>
                            <li className="feature-item text-primary-gradient">
                              Manual lead qualification/vetting
                            </li>
                            <li className="feature-item text-primary-gradient">
                              Appointment tracking
                            </li>
                            <li className="feature-item text-primary-gradient">
                              Pre-meeting reminders
                            </li>
                            <li className="feature-item text-primary-gradient">
                              Direct calendar bookings & sync
                            </li>
                            <li className="feature-item text-primary-gradient">
                              Up To 80h of Manual Lead Chasing per month
                            </li>
                          </ul>
                        </div>
                        <div className="price-and-cta text-center">
                          <p className="mb-5">
                            Designed for businesses where your average target is
                            worth $20,000+ LTV. LeadRoll consistently produces
                            ridiculous ROI in 6 months, or less.
                            <br />
                            <strong>Sales Appointments Guaranteed</strong>
                            <br />
                            <strong>
                              <em>Avg 600+ Hours Saved/mo per sales rep</em>
                            </strong>
                          </p>

                          <Link
                            to="/quiz"
                            className="btn btn-primary btn-lg shadow-none btn-red"
                          >
                            Get A Price
                          </Link>
                        </div>
                      </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Faq

export const faqQuery = graphql`
  query {
    sanityCompanyInfo {
      id
      faq {
        _key
        title
        body
      }
    }
  }
`
